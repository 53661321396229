import { AbilityMenuEntries, MenuEntryWithMetaData } from "@/shared/environment/ability.types";
import { MenuContext } from "@/shared/environment/menu/menu.types";
import { I18TType } from "@/shared/i18n/i18-utils";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { computed, ref, Ref, watchEffect } from "vue";
import DeleteProductDialog from "./delete-product-dialog.vue";

import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import { router } from "@/app/router.ts";

export function useMoreMenuProductItems(moreMenuContext: Ref<MenuContext>, t: I18TType): AbilityMenuEntries {
    const menuEntries = ref<MenuEntryWithMetaData[]>([]);

    const productName = useProductNames("singularLower");

    const { hasAccess: hasDeleteProductsAccess } = useHasAccess({
        resource: AccessResource.products,
        action: AccessAction.delete,
    });

    const { hasAccess: hasDataDisplayConfigsAccess } = useHasAccess({
        resource: AccessResource.data_display_configs,
        action: AccessAction.write,
    });

    const productId = computed(() => {
        if (moreMenuContext.value.__type === "productDetail") {
            return moreMenuContext.value.productId;
        } else {
            return undefined;
        }
    });

    const getMoreMenuEntries = (): MenuEntryWithMetaData[] => {
        if (!productId.value || !productName.value) return [];

        return [
            {
                entry: {
                    icon: "fa-regular fa-trash error-color",
                    text: t("product-and-asset.Delete {productsName}", { productsName: productName.value }),
                    click: () => {
                        moreMenuContext.value.callerContext.showDialogComponent({
                            getComponent: () => DeleteProductDialog,
                            props: {
                                productId: productId.value,
                                notifyAfterAction: moreMenuContext.value.callerContext.notifyDone,
                            },
                        });
                    },
                    elementAttrs: { "data-qs": "product-settings-toolbar-item-delete-product-button" },
                    visible: hasDeleteProductsAccess.value,
                },
                position: 2,
            },
            {
                entry: {
                    icon: "fa-regular fa-sliders-simple icon-color",
                    text: t("core.Configure view"),
                    click: () => {
                        const route = router.resolve({ path: "/admin/datadisplay/product" });
                        window.open(route.href);
                        moreMenuContext.value.callerContext.notifyDone(true);
                    },
                    elementAttrs: { "data-qs": "product-settings-toolbar-item-display-configuration-button" },
                    visible: hasDataDisplayConfigsAccess.value,
                },
                position: "last",
            },
        ];
    };

    watchEffect(() => {
        if (productId.value && productName.value) menuEntries.value = getMoreMenuEntries();
    });

    return { menuEntries };
}
