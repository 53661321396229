import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { AssetEdge, Maybe } from "@/shared/services/graphql/generated/admin-graph-types";
import { AssetSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { ComponentDefinition } from "@/shared/components/component-definition.model";
import { LazyLoadingDataSource } from "@/shared/components/scroll/lazy-loading-datasource";
import { Facet } from "@/shared/facets/facets.model";
import { DocumentNode } from "graphql";
import { computed, ComputedRef } from "vue";
import { advAsset } from "../data-objects/advanced-asset";
import AssetListCard from "./components/product-selection/asset-list-card.vue";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import { useI18n } from "vue-i18n";
import { getAggregationsForFacet } from "@/shared/facets/composables/facets-aggregation";
import { router } from "@/app/router.ts";

/**
 * Beschreibung eines Filtertypen. Mit dem für den User anzuzeigenden Text und dem Feld das damit gefiltert werden soll
 */
export interface FilterDescription {
    fieldName: string;
    displayTextKey: string;
}

export function createDataSource(
    showJumpToProduct: boolean,
    emitSelection: boolean
): LazyLoadingDataSource<Maybe<AssetEdge>, ComponentDefinition> {
    return new LazyLoadingDataSource<Maybe<AssetEdge>, ComponentDefinition>((item) => {
        return item
            ? {
                  getComponent: () => AssetListCard,
                  props: {
                      asset: advAsset(item.node),
                      showJumpToProduct: showJumpToProduct,
                      emitSelection: emitSelection,
                  },
              }
            : undefined;
    });
}

export function createListCards(
    dataSource: LazyLoadingDataSource<Maybe<AssetEdge>, ComponentDefinition>,
    assets: Array<Maybe<AssetEdge>>
): Array<ComponentDefinition> {
    dataSource.setSourceValues(assets ?? []);
    return dataSource.getResults();
}

export const getAggregations = async (facet: Facet, query: DocumentNode, variables: any) => {
    const aggregationsForDatafield = await consumerClient.query({
        query,
        variables,
        fetchPolicy: "no-cache",
    });

    return getAggregationsForFacet(facet, aggregationsForDatafield?.data?.assets?.aggregations);
};

export function useAssetsOverviewSort(isSearch: boolean) {
    const { t } = useI18n();

    const sortOrderItems = computed(() => {
        return [
            ...(isSearch
                ? [
                      {
                          id: "Ranking",
                          title: t("core.Ranking"),
                          value: {
                              field: AssetSortFields.score,
                              order: SortOrder.desc,
                          },
                      },
                  ]
                : []),
            {
                id: "Asset id ascending",
                title: t("product-and-asset.Asset id ascending"),
                value: {
                    field: AssetSortFields.assetId,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Asset id descending",
                title: t("product-and-asset.Asset id descending"),
                value: {
                    field: AssetSortFields.assetId,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Title ascending",
                title: t("product-and-asset.Title ascending"),
                value: {
                    field: AssetSortFields.title,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Title descending",
                title: t("product-and-asset.Title descending"),
                value: {
                    field: AssetSortFields.title,
                    order: SortOrder.desc,
                },
            },
        ];
    });

    return {
        sortOrderItems,
    };
}

export function useAssetsOverviewConfig() {
    const { t } = useI18n();

    const { hasAccess: hasConfigAccess } = useHasAccess({
        resource: AccessResource.data_display_configs,
        action: AccessAction.write,
    });

    const { hasAccess: hasFilterConfigAccess } = useHasAccess({
        resource: AccessResource.facet_collections,
        action: AccessAction.write,
    });

    const configMenuEntries: ComputedRef<MenuTextEntry[]> = computed(() => {
        return [
            {
                text: t("core.Configure view"),
                icon: "fa-regular fa-sliders-simple icon-color",
                click: () => {
                    const route = router.resolve({ path: "/admin/datadisplay/asset-selection" });
                    window.open(route.href, "_blank");
                },
                visible: hasConfigAccess.value,
                elementAttrs: { "data-qs": "asset-settings-toolbar-configure-button" },
            },
            {
                text: t("core.Configure filter"),
                icon: "fa-regular fa-sliders-simple icon-color",
                click: () => {
                    const route = router.resolve({ path: "/admin/filter/asset-selection" });
                    window.open(route.href, "_blank");
                },
                visible: hasFilterConfigAccess.value,
                elementAttrs: { "data-qs": "asset-settings-toolbar-configure-filter-button" },
            },
        ];
    });

    return {
        configMenuEntries,
    };
}
