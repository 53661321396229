import {
    Ability,
    DataProcessType,
    NavigationMenuEntry,
    Route,
    RouteElementWithParam,
    SearchTab,
    Widget,
} from "@/shared/environment/ability.types";
import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { AccessFeature } from "@/shared/access-control/access-control";
import { useDocumentationBreadCrumbs } from "../helpers/documentation-breadcrumbs";
import DocumentationImportWizard from "@/abilities/documentation/components/data-process/documentation-import-wizard.vue";
import RelatedDocumentsWidget from "@/abilities/documentation/widgets/related-documents/related-documents.widget.vue";
import DocumentationSearch from "../pages/documentation-search.vue";
import { keyText } from "@/shared/i18n/translation-types";
import { useDocumentationsBreadCrumb } from "@/abilities/documentation/helpers/documentations-breadcrumb.ts";

export const DOCUMENTATION_ALIAS = "documentation";

export class DocumentationAbility implements Ability {
    alias = DOCUMENTATION_ALIAS;

    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["asset", "product", "mechanicArticle", "commonArticle"],
            widgetComponent: RelatedDocumentsWidget,
        },
    ];

    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            title: keyText("documentation.Documentation"),
            icon: "fa-regular fa-book-blank",
            internalRoute: {
                alias: CommonRouteAliases.documentations,
                access: {
                    accessFeature: AccessFeature.documentation_menu,
                },
                queryParams: true,
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: CommonRouteAliases.documentations,
            params: [],
            getComponent: () => import("@/abilities/documentation/pages/documentation-overview.vue"),
            parentAliases: [CommonRouteAliases.asset, CommonRouteAliases.product, CommonRouteAliases.mechanicArticle],
            isRoot: true,
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return useDocumentationsBreadCrumb(parentElements, currentElement);
            },
            getSearchParams: () => {
                return { searchTab: CommonSearchTabAliases.documentations };
            },
        },
        {
            alias: CommonRouteAliases.documentationDetail,
            params: ["contentMapId", "topicId"],
            isRoot: true,
            getComponent: () => import("@/abilities/documentation/pages/documentation-detail-page.vue"),
            parentAliases: [CommonRouteAliases.documentations],
            getBreadCrumbs: (_parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return useDocumentationBreadCrumbs(currentElement);
            },

            getSearchParams: () => {
                return { searchTab: CommonSearchTabAliases.documentations };
            },
        },
    ];

    getSearchTabs: () => Array<SearchTab> = () => [
        {
            alias: CommonSearchTabAliases.documentations,
            title: keyText("documentation.Documentation"),
            searchComponent: {
                getComponent: () => DocumentationSearch,
            },
        },
    ];

    getDataProcessTypes = (): Array<DataProcessType> => [
        {
            type: ProcessType.documentation,
            tileSettings: {
                title: "documentation.Documentation",
                text: "documentation.Information on importing documentation for your products",
                icon: "fa-solid fa-file-lines documentation-color",
                cssClass: "documentation-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => DocumentationImportWizard,
                props: {
                    title: "documentation.Create New Process (Documentation)",
                },
            },
        },
    ];
}
