import { useAutoComplete } from "@/shared/autocomplete/autocomplete";
import { computed, ComputedRef, Ref, watch } from "vue";
import { SearchTab } from "@/shared/environment/ability.types";
import { uniqBy } from "lodash";
import {
    SearchSuggestion,
    SuggestionListItem,
    SuggestionSearchTab,
} from "@/shell/pages/header/search/header-search.types";
import { useUserSettings } from "@/shared/settings/user-settings";
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";
import { useTextObjectTranslation } from "@/shared/i18n/translation-types";
import { useDatafieldDefinition } from "@/shared/datafield-definitions/datafield-definition";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";

export const MIN_SEARCH_LENGTH = 3;

/**
 * Custom composable function that provides search suggestions based on the search input value, search tabs, and search parameters.
 *
 * @param searchInputValue - The reactive reference to the search input value.
 * @param searchTabs - The reactive reference to the search tabs.
 * @param searchParams - The computed reference to the search parameters.
 * @returns An object containing functions and computed properties related to search suggestions.
 */
export const useSearchSuggestions = (
    searchInputValue: Ref<string>,
    searchTabs: Ref<SearchTab[]>,
    searchParams: ComputedRef<Record<string, string>>
) => {
    // Constants for maximum entries in history and suggestions
    const MAX_HISTORY_ENTRIES = 10;
    const MAX_SUGGESTIONS_ENTRIES = 5;
    const HISTORY_KEY = "search_history";

    // Get screen size information
    const { isXSmall } = useScreenSize();

    // Get screen size information
    const { trans } = useTextObjectTranslation();

    // Retrieve and update user settings for search history
    const { data: searchHistory, update } = useUserSettings<SuggestionListItem[] | undefined>(HISTORY_KEY);
    // Load autocomplete results based on search input, tabs, and parameters
    const { result, load } = useAutoComplete(searchInputValue, searchTabs, searchParams);

    // Compute datafield definition title filter based on search history for facet suggestions
    const datafieldDefinitionTitleFilter = computed(() => {
        const datafieldDefinitionIds =
            searchHistory.value
                ?.filter((item) => item.itemType === "facet" && item.id !== undefined)
                .map((item) => item.id!) ?? [];
        return {
            filter: {
                ids: datafieldDefinitionIds,
            },
            acceptedLanguages: getDataQueryLanguages(),
        };
    });

    // Load datafield definitions titles based on the filter
    const { datafieldDefintionsTitles, loadDatafieldDefinitionsTitles } =
        useDatafieldDefinition(datafieldDefinitionTitleFilter);

    // Watch for changes in search history and load datafield definitions titles if necessary for facet suggestions
    watch([searchHistory], () => {
        if (searchHistory.value && datafieldDefinitionTitleFilter.value.filter.ids.length > 0) {
            loadDatafieldDefinitionsTitles();
        }
    });

    // Get search tab by alias with translated text for display
    function getSuggestionSearchTabByAlias(alias: string): SuggestionSearchTab | undefined {
        const searchTab = searchTabs.value.find((tab) => tab.alias === alias);
        return searchTab ? { displayText: trans(searchTab.title), alias: searchTab.alias } : undefined;
    }

    // Compute all suggestions based on search input and history
    const suggestionsAll = computed(() => {
        let focusIndex = -1;
        const allSuggestions: SearchSuggestion[] = [];

        // Add content suggestions if available and input length is sufficient
        if (result.value?.domainSuggestions.length > 0 && searchInputValue.value.length >= MIN_SEARCH_LENGTH) {
            const domainSuggestions: SuggestionListItem[] = result.value.domainSuggestions.map((x) => {
                focusIndex++;
                const searchTab = getSuggestionSearchTabByAlias(x.searchTabAlias);
                return {
                    itemType: "domain",
                    text: x.title,
                    ...(searchTab && { searchTab: searchTab }),
                    focusIndex,
                };
            });
            allSuggestions.push({ title: "Suggestions", type: "domain", items: domainSuggestions });
        }

        // Add facet suggestions if available and input length is sufficient
        if (result.value?.facetSuggestions.length > 0 && searchInputValue.value.length >= MIN_SEARCH_LENGTH) {
            const facetSuggestions: SuggestionListItem[] = [];

            for (const suggestion of result.value.facetSuggestions) {
                if (facetSuggestions.length === MAX_SUGGESTIONS_ENTRIES) break;

                for (const tabAlias of suggestion.searchTabAliases || []) {
                    if (facetSuggestions.length === MAX_SUGGESTIONS_ENTRIES) break;
                    const searchTab = getSuggestionSearchTabByAlias(tabAlias);
                    const listItem: SuggestionListItem = {
                        itemType: "facet",
                        id: suggestion.datafieldDefinitionId,
                        text: suggestion.title,
                        value: suggestion.value,
                        parentTitle: suggestion.parentTitle,
                        ...(searchTab && {
                            searchTab: searchTab,
                        }),
                        focusIndex: ++focusIndex,
                    };
                    facetSuggestions.push(listItem);
                }
            }

            allSuggestions.push({ title: "Categories", type: "facet", items: facetSuggestions });
        }

        // Add recent search history if available and input is empty
        if (searchHistory.value && searchHistory.value.length > 0 && searchInputValue.value.length === 0) {
            // Show a maximum of 8 entries if the screen is extra small - < 600px
            const historyEntries = isXSmall.value ? searchHistory.value?.slice(0, 8) : searchHistory.value;
            allSuggestions.push({
                title: "Recent searches",
                type: "history",
                items: historyEntries.map((entry) => {
                    //for each facet entry, update the parent title with the localized title
                    let facetTitle = undefined;
                    if (entry.itemType === "facet") {
                        const datafieldDefinition =
                            datafieldDefintionsTitles.value?.fieldDefinitions?.fieldDefinitions.find(
                                (datafieldDefintion) => datafieldDefintion?.node.id === entry.id
                            );
                        facetTitle = datafieldDefinition?.node?.localizations?.title ?? entry.text;
                    }
                    focusIndex++;
                    const searchTab = getSuggestionSearchTabByAlias(entry.searchTab?.alias ?? "");
                    //assemble the suggestion list item
                    return {
                        ...entry,
                        focusIndex,
                        ...(searchTab && {
                            searchTab: searchTab,
                        }),
                        ...(facetTitle && {
                            parentTitle: facetTitle,
                        }),
                    };
                }),
            });
        }

        return allSuggestions;
    });

    // Save new search items to the history
    const saveHistoryItems = async (newItem: SuggestionListItem) => {
        let history = searchHistory.value ?? [];

        // Check if the first item in history is the same as the new item
        if (
            history.length === 0 || // If history is empty, add the new item
            `${history[0].text}-${history[0].itemType}-${history[0].searchTab?.alias ?? ""}` !==
                `${newItem.text}-${newItem.itemType}-${newItem.searchTab?.alias ?? ""}` // Check if the first item is different from newItem
        ) {
            // Add new item to the beginning of the history
            history.unshift(newItem);
        }

        // Remove duplicate entries based on text and tab alias
        history = uniqBy(history, (item) => `${item.text}-${item.itemType}-${item.searchTab?.alias ?? ""}`);

        // Trim history to the maximum allowed entries
        if (history.length > MAX_HISTORY_ENTRIES) {
            history.pop();
        }

        // Update the history in user settings
        await update(history);
    };

    return { saveHistoryItems, suggestionsAll, load };
};
