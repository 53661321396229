<template>
    <div class="shop-wrapper">
        <div v-if="loading">
            <div v-if="!flat" class="skeleton-line"></div>
            <div class="flex flex-space-between q-pt-sm">
                <div class="skeleton-line" style="width: 50px"></div>
                <div class="skeleton-line" style="width: 100px"></div>
            </div>
            <div class="flex flex-space-between q-pt-sm no-wrap">
                <div class="skeleton-square" style="height: 60px; min-width: 80px"></div>
                <div class="skeleton-line percent50" style="height: 60px; min-width: 120px"></div>
            </div>
        </div>
        <template v-else>
            <!-- Status messages for when shop is offline -->
            <div v-if="!isShopOnline" class="row no-wrap q-my-md">
                <div class="q-py-sm" style="width: 28px; max-width: 28px">
                    <i class="fa-regular fa-circle-info fa-2x" style="color: var(--q-negative)"></i>
                </div>

                <div class="q-pl-md">
                    {{ $t("fdi-shop.The store is currently unavailable due to maintenance work or errors") }}
                </div>
            </div>
            <div v-else-if="!shopItem" class="row no-wrap q-my-md items-center">
                <div class="q-py-sm" style="width: 28px; max-width: 28px">
                    <i class="fa-regular fa-circle-info fa-2x" style="color: var(--q-negative)"></i>
                </div>
                <div class="q-pl-md">{{ $t("fdi-shop.Article not found") }}</div>
            </div>

            <!-- Status messages for when when selected amount is higher, then available amount -->
            <div v-else-if="moreSelectedThenAvailable" class="row no-wrap q-my-md">
                <div>
                    <i class="fa-regular fa-circle-info" style="color: var(--q-warning)"></i>
                </div>

                <div v-if="amountAvailable > 0" class="q-pl-sm">
                    {{
                        $t("fdi-shop.{additionalAmount} more orderable with unknown delivery time", {
                            additionalAmount: (amountSelected - amountAvailable).toLocaleString(getUiLanguage(), {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }),
                        })
                    }}
                </div>
                <div v-else-if="shopItem" class="q-pl-md">
                    {{ $t("fdi-shop.Orderable with unknown delivery time") }}
                </div>
            </div>
            <div ref="shopWidgetRef" :class="{ 'shop-widget': true, 'widget-flat': flat }">
                <!-- Availability Indicator and Price Display -->
                <div :class="{ 'space-between-flex': true && !flat, 'availability-indicator-flat': flat }">
                    <AvailabilityIndicator :amount-available="amountAvailable" :stock-status="stockStatus" />
                    <div
                        v-if="!showPriceOnRequest && isShopOnline && shopItem"
                        class="price-single-product black-bold-text"
                    >
                        {{ singlePrice }} {{ currencySign }}
                    </div>
                    <div v-else>
                        {{ $t("fdi-shop.No price available") }}
                    </div>
                </div>

                <!-- Shop Controls -->
                <div class="shop-controls">
                    <div class="space-between-flex">
                        <div>{{ $t("fdi-shop.Amount") }}</div>
                        <div v-if="!showPriceOnRequest" class="black-bold-text">
                            {{ $t("fdi-shop.Total") }}: {{ totalPrice }} {{ currencySign }}
                        </div>
                    </div>
                    <div class="space-between-flex">
                        <NumberInput
                            class="amount-input"
                            :min="0"
                            :max="!isShopOnline ? 0 : Infinity"
                            @on-change="amountInputChange"
                            :validate-on-edit="true"
                            :disabled="showPriceOnRequest || !isShopOnline || !shopItem"
                            :warning-highlight="moreSelectedThenAvailable"
                        ></NumberInput>
                        <QitBtn
                            v-if="!showPriceOnRequest"
                            :label="$q.screen.gt.md ? $t('fdi-shop.Add to cart') : undefined"
                            color="primary"
                            icon="fa-regular fa-cart-plus text-on-primary-color"
                            class="checkout-btn"
                            @click="addPartToBasket"
                            :disable="!checkoutEnabled"
                        >
                            <q-tooltip self="top right" v-if="!shopItem">{{
                                $t("fdi-shop.Article not found")
                            }}</q-tooltip>
                        </QitBtn>
                        <QitBtn
                            v-else
                            :label="$q.screen.gt.md ? $t('fdi-shop.Request price') : undefined"
                            color="primary"
                            icon="fa-regular fa-message-question text-on-primary-color"
                            class="checkout-btn"
                            @click="sendPriceRequest"
                            :disable="!isShopOnline"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import {
    addBasketItemMutation,
    getArticleShopInfos,
    addPriceRequest,
} from "@/abilities/fdi-shop/graphql/basket.shopquery";
import { getBasketIdForCurrentUser, getShopClient, updateBasket } from "@/abilities/fdi-shop/graphql/fdi-shop-client";
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import NumberInput from "@/abilities/fdi-shop/pages/components/number-input.vue";
import { useQuasar } from "quasar";
import { useShopStatusStore } from "@/abilities/fdi-shop/stores/shop-status";
import { get, set } from "@vueuse/core";
import AvailabilityIndicator from "../availability-indicator.vue";
import { getUiLanguage } from "@/shared/services/providers/language-provider";

// Props
const props = defineProps<{
    widgetContext: WidgetContext;
}>();

const { notify } = useQuasar();
const { t } = useI18n();
const { isShopOnline } = useShopStatusStore();

const amountSelected = ref(0);

const loading = ref(true);

type ShopItemInfo = {
    price: number | null;
    showPriceOnRequest: boolean;
    amountAvailable: number;
    stockStatus: number;
    currencySign: string;
};

const shopItem = ref<null | ShopItemInfo>(null);

const articleId = computed(() => {
    if (props.widgetContext.__type === "shopContext") {
        return props.widgetContext.articleId;
    } else {
        return "";
    }
});

const checkoutEnabled = computed(() => {
    if (!isShopOnline) return false;
    if (shopItem.value === null) return false;
    if (amountSelected.value <= 0) return false;
    if (shopItem.value.amountAvailable <= 0) return false;
    return true;
});

const flat = computed(() => {
    if (props.widgetContext.__type === "shopContext") {
        return props.widgetContext.flatView;
    } else {
        return false;
    }
});

const showPriceOnRequest = computed(() => {
    return shopItem.value?.showPriceOnRequest ?? false;
});

const amountAvailable = computed(() => {
    return shopItem.value?.amountAvailable ?? 0;
});

const stockStatus = computed(() => {
    return shopItem.value?.stockStatus ?? -1;
});

const currencySign = computed(() => {
    return shopItem.value?.currencySign ?? "";
});

const totalPrice = computed(() => {
    if (shopItem.value === null) {
        return 0;
    }
    if (shopItem.value.price === null) {
        return 0;
    } else {
        return (shopItem.value.price * amountSelected.value).toLocaleString(getUiLanguage(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
});

const singlePrice = computed(() => {
    if (shopItem.value === null) {
        return 0;
    } else {
        return shopItem.value.price?.toLocaleString(getUiLanguage(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
});

const moreSelectedThenAvailable = computed(() => get(amountSelected) > get(amountAvailable));

watch(
    () => props.widgetContext,
    () => {
        loading.value = true;
        getProductInfos()
            .then((result) => {
                shopItem.value = result;
            })
            .catch(() => {
                shopItem.value = null;
            })
            .finally(() => {
                loading.value = false;
            });
    },
    { deep: true, immediate: true }
);

function amountInputChange(val: number) {
    set(amountSelected, val);
}

async function sendPriceRequest() {
    const client = await getShopClient();
    const basketId = getBasketIdForCurrentUser();
    if (!client || !basketId || !articleId.value) {
        return;
    }
    try {
        client
            .mutate({
                mutation: addPriceRequest,
                variables: {
                    articleId: articleId.value,
                },
            })
            .then(() => {
                notify({
                    message: t("fdi-shop.Request sent successfully"),
                    type: "positive",
                    timeout: 5_000,
                });
            });
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

async function addPartToBasket() {
    const client = await getShopClient();
    const basketId = getBasketIdForCurrentUser();
    if (!client || !basketId || !articleId.value) {
        return;
    }
    try {
        const { data } = await client.mutate({
            mutation: addBasketItemMutation,
            variables: {
                BasketId: basketId,
                Amount: Number(amountSelected.value),
                ProductId: articleId.value,
            },
        });
        notify({
            message: t("fdi-shop.Article added to cart"),
            type: "positive",
            timeout: 5_000,
        });
        updateBasket();
        return data;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

async function getProductInfos(): Promise<ShopItemInfo | null> {
    const client = await getShopClient();
    if (!client || !articleId.value) {
        return null;
    }
    try {
        const { data } = await client.query({
            query: getArticleShopInfos,
            variables: {
                articleId: articleId.value,
            },
        });
        return {
            price: data.article.price.price,
            showPriceOnRequest: data.article.showPriceOnRequest,
            amountAvailable: data.article.stock.stock,
            stockStatus: data.article.stock.stockStatus,
            currencySign: data.article.price.currency.sign,
        };
    } catch (error) {
        //error
        return null;
    }
}
</script>

<style lang="scss" scoped>
.shop-wrapper {
    border-top: 1px solid $default-border-color;
    padding: $spacing-l;
}
.shop-widget {
    color: $medium-text-color;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-flow: space-between;
    gap: $spacing-l;
}

.widget-flat {
    flex-direction: row;
    border-top: 0px;

    .shop-controls {
        flex-shrink: 2;
        flex-basis: 60%;
    }

    .availability-indicator-flat {
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
        gap: $spacing-m;
        flex-grow: 2;
        flex-basis: 40%;
    }
}

.space-between-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
}

.price-single-product {
    font-size: $bigger-font-size;
}

.black-bold-text {
    font-weight: bold;
    color: $default-text-color;
}

.amount-input {
    margin-right: $spacing-xl;
    flex-basis: 20%;
    flex-grow: 1;
}

.checkout-btn {
    flex-grow: 2;
    flex-basis: 50%;
}

.shop-controls {
    gap: $spacing-s;
    display: flex;
    flex-direction: column;
}
</style>
