import { AsyncLoadedBreadCrumbs, RouteElementWithParam } from "@/shared/environment/ability.types";
import { computed } from "vue";
import BreadcrumbSpan from "@/shared/breadcrumbs/breadcrumb-span.vue";
import { useDocQueryParams } from "@/shared/composables/doc-query-params.ts";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper.ts";

export function useDocumentationsBreadCrumb(
    parentElements: Array<RouteElementWithParam>,
    currentElement: RouteElementWithParam
): AsyncLoadedBreadCrumbs {
    const { queryParams, loading } = useDocQueryParams();

    const items = computed(() => [
        {
            content: {
                componentDefinition: {
                    getComponent: () => BreadcrumbSpan,
                    props: {
                        title: "documentation.Documentation",
                        icon: "fa-book-blank",
                    },
                },
            },
            getRouterTo: () => {
                return createRouteFromRouteElements(parentElements, currentElement, queryParams.value);
            },
        },
    ]);

    return {
        items,
        loading,
    };
}
