<template>
    <Dialog
        :visible="visible"
        :title="$t('collections.Create new collection')"
        @on-close="onClose"
        data-qs="collection-create-dialog"
    >
        <template #content>
            <CollectionsForm :loading="loading" @on-create="onCreate" @on-back="onClose" />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, toRefs } from "vue";
import Dialog from "@/shared/components/dialog/dialog.vue";
import {
    CollectionsDocument,
    useCreateCollectionMutation,
} from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import CollectionsForm from "@/abilities/collections/components/collections-form.vue";
import { CollectionData } from "@/abilities/collections/models/collections.models.ts";

const props = defineProps<{ visible: boolean }>();

const emit = defineEmits<{
    onClose: [];
    onCreate: [collectionId: string];
}>();

const { visible } = toRefs(props);
const loading = ref<boolean>(false);

const { mutate } = useCreateCollectionMutation();

const onCreate = async (newCollection: CollectionData) => {
    loading.value = true;

    try {
        const result = await mutate(
            { spec: newCollection },
            { awaitRefetchQueries: true, refetchQueries: [{ query: CollectionsDocument }] }
        );
        emit("onCreate", result?.data?.createCollection || "");
    } catch (error) {
        console.error(error);
    } finally {
        loading.value = false;
    }
};

const onClose = () => {
    emit("onClose");
};
</script>

<style lang="scss" scoped>
.collections-form {
    background-color: $white;
    padding: 0;
}
</style>
