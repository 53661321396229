<template>
    <div class="mechanic-location-main">
        <div class="skeleton-container" v-if="loading">
            <div v-for="n in 3" style="width: 60%; margin-bottom: 4px" :key="n">
                <div class="skeleton-square" style="height: 40px" />
            </div>
        </div>
        <ErrorMessage v-else-if="error" :error-message="$t('core.Oops, something went wrong')" />
        <div v-else-if="isEmpty" class="error-message">
            <h4>{{ $t("mechanic.No installation location found") }}</h4>
            <QitButton @click="goBack" :label="$t('core.Back')" />
        </div>
        <LazyLoadScrollView
            v-else
            class="selection-list"
            :reach-bottom-text="$t('mechanic.Loading installation locations')"
            @load-more="onLoadMore"
        >
            <div>
                <TilesGrid
                    :tiles="tiles"
                    :always-single-col="true"
                    :grid-gap="false"
                    @link-clicked="linkClicked"
                    @select="emitSelect"
                />
            </div>
        </LazyLoadScrollView>
    </div>
</template>

<script setup lang="ts">
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { computed, watch } from "vue";
import LazyLoadScrollView from "@/shared/components/scroll/lazy-loading-scroll-view.vue";
import ErrorMessage from "@/shared/components/placeholder/error-message.vue";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useMechanicLocationDataSource } from "./mechanic-location-for-qualification.model";
import { MechanicQueryResult } from "@/shared/services/graphql/generated/admin-graph-types";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";
import TilesGrid from "@/shared/components/tiles/tiles-grid.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { useMechanicRoute } from "@/abilities/mechanic/composable/mechanic-route";
import { router } from "@/app/router.ts";

const props = defineProps<{
    selectionData: QualificationLevelSelectionResult;
    isLastLevel: boolean;
}>();

const emit = defineEmits<{
    (e: "select", ...params: any): void;
    (e: "linkClicked"): void;
    (e: "back"): void;
}>();

const goBack = () => {
    emit("back");
};

const emitSelect = (...params: any) => {
    emit("select", ...params);
};

const linkClicked = () => {
    emit("linkClicked");
};

const { loading, error, fetchMore, mechanics, tiles, paths } = useMechanicLocationDataSource(
    computed(() => {
        return {
            first: 10,
            acceptedLanguages: getDataQueryLanguages(),
            articleId: props.selectionData.article,
            product: props.selectionData.product,
            asset: props.selectionData.asset,
        };
    }),
    !props.isLastLevel
);

const lazyLoadAmount = 10;

const isEmpty = computed(() => tiles.value.length <= 0);

const onLoadMore = (callBackAfterLoaded: (hasMoreElements: boolean) => void) => {
    if (!mechanics.value) {
        // Noch nicht initialisiert. Also wahrscheinlich gibt es schon was zum Nachladen
        callBackAfterLoaded(true);
        return;
    }
    const mechanicsValue = mechanics.value as MechanicQueryResult;
    const lastMechanicValue = mechanicsValue.mechanics[mechanicsValue.mechanics.length - 1];
    if (!lastMechanicValue || !lastMechanicValue.cursor) {
        callBackAfterLoaded(false);
        return;
    }
    loadMore(fetchMore, lastMechanicValue.cursor, lazyLoadAmount).then((data) => {
        const isAtEnd = data?.data.mechanics.mechanics.length == 0;
        callBackAfterLoaded(!isAtEnd);
    });
    return;
};

const { getAbsolutePathToMechanicLocation } = useMechanicRoute();

watch(loading, () => {
    // Prüfe, ob nur Eintrag vorhanden ist und falls ja, dann springe dort gleich hin
    if (props.isLastLevel) {
        if (loading.value === false) {
            if (paths.value.length === 1) {
                router.push(
                    getAbsolutePathToMechanicLocation(
                        props.selectionData.product,
                        props.selectionData.asset,
                        paths.value[0]
                    )
                );
            }
        }
    }
});
</script>

<style lang="scss">
.installation-location-selection .wizard-title {
    background-color: $white !important;
}

.mechanic-location-main .tile-grid div:first-of-type .selection-links {
    border-top: none;
}
</style>

<style lang="scss" scoped>
.mechanic-location-main {
    height: 100%;

    .tile-grid {
        background-color: $white;
    }
}

.skeleton-container {
    display: flex;
    flex-direction: column;
    padding-top: $spacing-l;
    padding-left: $spacing-xl;
    gap: $spacing-l;
}

.error-message {
    padding: $spacing-xl;
}
</style>
