import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config.ts";
import { useLazyQuery } from "@vue/apollo-composable";
import { computed, onMounted, Ref, watchEffect } from "vue";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model.ts";
import {
    ActivityCollectionEntryNode,
    ArticleCollectionEntryNode,
    Collection,
    ContentCollectionEntryNode,
    ContentSortFields,
    LanguageWithWildcard,
    SortOrder,
} from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { mapToDocumentCollectionEntry } from "@/abilities/collections/mapper/document-collection-entry.mapper.ts";
import { getContentsOfCollection } from "@/abilities/collections/graphql/collections.query.ts";
import { computedAsync } from "@vueuse/core";
import { mapToArticleCollectionEntry } from "@/abilities/collections/mapper/article-collection-entry.mapper.ts";
import { mapToMechanicArticleCollectionEntry } from "@/abilities/collections/mapper/mechanic-article-collection-entry.mapper.ts";
import { useI18n } from "vue-i18n";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";
import { mapToActivityCollectionEntry } from "@/abilities/collections/mapper/activity-collection-entry.mapper.ts";
import {
    ActivityCollectionEntry,
    ArticleCollectionEntry,
    CollectionEntryTypes,
    DocumentCollectionEntry,
    MechanicArticleCollectionEntry,
} from "@/abilities/collections/components/collection-entry.types.ts";
import { MechanicCollectionEntryNode } from "@/shared/services/graphql/generated/admin-graph-types.ts";

export interface CollectionContentFilterVariables {
    id: string;
    first: number;
    sortId: ContentSortFields;
    sortOrder: SortOrder;
    acceptedLanguages: LanguageWithWildcard[];
    after?: string;
}

export const useCollectionContent = (filterVariables: Ref<CollectionContentFilterVariables>) => {
    const { t } = useI18n();

    onMounted(async () => {
        await loadContentsOfCollection();
    });

    const { hasAccess: hasSparePartsAccess, loading: hasSparePartsAccessLoading } = useHasAccess({
        featureID: AccessFeature.spare_parts,
    });
    const { hasAccess: hasActivitiesAccess, loading: hasActivitiesAccessLoading } = useHasAccess({
        featureID: AccessFeature.activities,
    });

    const {
        result: dataDisplayForDocSelectionResult,
        error: dataDisplayForDocSelectionError,
        loading: dataDisplayForDocSelectionLoading,
        getDataDisplayPropertiesByConfig: getDataDisplayPropertiesByConfigForDocSelection,
    } = useDataDisplayConfig(DataDisplayConfigId.documentSelection);

    const {
        result: dataDisplayForArticleResult,
        error: dataDisplayForArticleError,
        loading: dataDisplayForArticleLoading,
        getDataDisplayPropertiesByConfig: getDataDisplayPropertiesByConfigForArticle,
    } = useDataDisplayConfig(DataDisplayConfigId.article, hasSparePartsAccess);

    const {
        result: dataDisplayForPartsResult,
        error: dataDisplayForPartsError,
        loading: dataDisplayForPartsLoading,
        getDataDisplayPropertiesByConfig: getDataDisplayPropertiesByConfigForParts,
    } = useDataDisplayConfig(DataDisplayConfigId.parts, hasSparePartsAccess);

    const {
        result: dataDisplayForActivitiesResult,
        error: dataDisplayForActivitiesError,
        loading: dataDisplayForActivitiesLoading,
        getDataDisplayPropertiesByConfig: getDataDisplayPropertiesByConfigForActivities,
    } = useDataDisplayConfig(DataDisplayConfigId.activitiesSelection, hasActivitiesAccess);
    //--------------------------------------------------------

    const {
        loading: contentLoading,
        error: contentError,
        result,
        fetchMore,
        load,
        refetch,
    } = useLazyQuery(getContentsOfCollection(), {});

    const loadContentsOfCollection = async () => {
        if (
            !dataDisplayForDocSelectionResult.value ||
            (hasSparePartsAccess.value && !dataDisplayForArticleResult.value) ||
            (hasSparePartsAccess.value && !dataDisplayForPartsResult.value) ||
            (hasActivitiesAccess.value && !dataDisplayForActivitiesResult.value) ||
            filterVariables.value.id === "-"
        )
            return;

        await load(
            getContentsOfCollection(
                dataDisplayForDocSelectionResult.value,
                hasSparePartsAccess.value ? dataDisplayForArticleResult.value : undefined,
                hasSparePartsAccess.value ? dataDisplayForPartsResult.value : undefined,
                hasActivitiesAccess.value ? dataDisplayForActivitiesResult.value : undefined
            ),
            filterVariables.value
        );
    };

    watchEffect(async () => {
        if (
            dataDisplayForDocSelectionResult.value &&
            ((hasSparePartsAccess.value && dataDisplayForArticleResult.value) || !hasSparePartsAccess.value) &&
            ((hasSparePartsAccess.value && dataDisplayForPartsResult.value) || !hasSparePartsAccess.value) &&
            ((hasActivitiesAccess.value && dataDisplayForActivitiesResult.value) || !hasActivitiesAccess.value) &&
            filterVariables.value
        )
            await loadContentsOfCollection();
    });

    const getEntries = async (
        collection: Collection
    ): Promise<
        (ArticleCollectionEntry | DocumentCollectionEntry | MechanicArticleCollectionEntry | ActivityCollectionEntry)[]
    > => {
        if (!collection.entries?.entries || collection.entries?.entries.length === 0) return [];

        //Convert all collection entries from server. converted article entries must be extended by subheading
        const collectionEntries: CollectionEntryTypes[] = [];

        for (const entry of collection.entries.entries) {
            if (!entry) continue;

            const node = entry.node as
                | ContentCollectionEntryNode
                | ArticleCollectionEntryNode
                | MechanicCollectionEntryNode
                | ActivityCollectionEntryNode;

            if (node.__typename === "ContentCollectionEntryNode") {
                collectionEntries.push(
                    mapToDocumentCollectionEntry(entry, getDataDisplayPropertiesByConfigForDocSelection)
                );
            } else if (node.__typename === "ArticleCollectionEntryNode") {
                collectionEntries.push(mapToArticleCollectionEntry(entry, getDataDisplayPropertiesByConfigForArticle));
            } else if (node.__typename === "MechanicCollectionEntryNode") {
                const mechanicEntry = await mapToMechanicArticleCollectionEntry(
                    entry,
                    getDataDisplayPropertiesByConfigForParts,
                    t("mechanic.Mechanics")
                );
                if (mechanicEntry) collectionEntries.push(mechanicEntry);
            } else if (node.__typename === "ActivityCollectionEntryNode") {
                collectionEntries.push(
                    mapToActivityCollectionEntry(entry, getDataDisplayPropertiesByConfigForActivities)
                );
            }
        }

        return collectionEntries;
    };

    const entries = computedAsync(async () => {
        if (filterVariables.value.id === "-") return null;

        return result.value?.collection ? await getEntries(result.value.collection) : null;
    }, null);

    const loading = computed(
        () =>
            contentLoading.value ||
            dataDisplayForDocSelectionLoading.value ||
            dataDisplayForArticleLoading.value ||
            dataDisplayForPartsLoading.value ||
            dataDisplayForActivitiesLoading.value ||
            hasSparePartsAccessLoading.value ||
            hasActivitiesAccessLoading.value
    );

    watchEffect(() => {
        if (dataDisplayForDocSelectionError.value) console.error(dataDisplayForDocSelectionError.value);
        if (dataDisplayForArticleError.value) console.error(dataDisplayForArticleError.value);
        if (dataDisplayForPartsError.value) console.error(dataDisplayForPartsError.value);
        if (dataDisplayForActivitiesError.value) console.error(dataDisplayForActivitiesError.value);
        if (contentError.value) console.error(contentError.value);
    });

    const error = computed(() => {
        return (
            dataDisplayForDocSelectionError.value ||
            dataDisplayForArticleError.value ||
            dataDisplayForPartsError.value ||
            dataDisplayForActivitiesError.value ||
            contentError.value
        );
    });

    return {
        loading,
        error,
        fetchMore,
        refetch,
        result,
        entries,
    };
};
