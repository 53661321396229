<template>
    <q-scroll-area class="small-container fit">
        <q-infinite-scroll @load="fireLoadMore">
            <div class="q-pr-sm">
                <slot />
            </div>
            <template #loading>
                <div class="row loading justify-center q-pa-md">
                    <q-spinner-ios size="2em" />
                    <div class="q-pl-sm">{{ loadMoreText }}</div>
                </div>
            </template>
        </q-infinite-scroll>
    </q-scroll-area>
</template>
<!--
Currently the loading template in the q-infinite-scroll leads to an error in the unit test with vitest,
therefore this is not used until further notice. This problem may be solved with a quasar update.
At the time of the error quasar ^2.15.4 was used.
The current workaround is to stub the q-spinner-ios eg. activities.spec.vitest
-->
<script setup lang="ts">
defineProps<{
    loadMoreText?: string;
}>();

const emit = defineEmits<{
    loadMore: [done: (stop?: boolean | undefined) => void];
}>();

const fireLoadMore = (_index: number, done: (stop?: boolean | undefined) => void) => {
    emit("loadMore", done);
};
</script>

<style lang="scss" scoped>
.loading {
    .q-infinite-scroll__loading.invisible & {
        display: none;
    }
}
</style>
