<template>
    <div>
        <header class="header-component">
            <q-toolbar class="text-primary" :class="{ 'q-pr-sm q-pl-md': !isXSmall, 'q-px-sm': isXSmall }">
                <q-btn
                    v-if="isXSmall"
                    unelevated
                    :ripple="false"
                    padding="sm"
                    icon="fa-solid fa-bars"
                    size="md"
                    class="grey-hover-mobile q-pa-sm q-mr-sm col-auto"
                    data-qs="mobile-menu-button"
                    @click="$emit('toggleNav')"
                />
                <div v-else class="menu-logo col-auto">
                    <router-link :to="pathToRoot()" class="router-link q-mt-xs">
                        <div
                            v-if="!imageLoaded"
                            style="width: 225px; height: 30px"
                            class="skeleton-square"
                            @click="logoClickedEasterEgg"
                        />
                        <img
                            v-show="imageLoaded"
                            ref="menuLogoImgRef"
                            class="menu-logo-img"
                            :class="{ 'theme-title-long': themeTitleIsLong }"
                            data-qs="header-toolbar-easter-egg-button"
                            :src="themeLogoURL ? themeLogoURL : QUANOS_LOGO"
                            @click="logoClickedEasterEgg"
                            @load="imageLoaded = true"
                        />
                    </router-link>
                    <div v-if="themeTitleIsLong && themeDefinitions?.showTitle" class="tenant-title under-image">
                        {{ themeTitle }}
                    </div>
                </div>
                <div
                    v-if="!isMediumSmall && !themeTitleIsLong && themeDefinitions?.showTitle"
                    class="tenant-title col-auto"
                >
                    {{ themeTitle }}
                </div>
                <HeaderSearch
                    class="fit q-my-sm col"
                    :class="{ 'q-mx-lg': !isXSmall, xx: isXSmall }"
                    @on-click-search="onSearch"
                    :search-phrase="searchPhrase"
                />
                <q-space />
                <icon-menu class="col-auto" />
            </q-toolbar>
        </header>
        <q-resize-observer :debounce="0" @resize="onResize" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { parse } from "qs";
import { RouteLocationNormalizedLoaded, RouteLocationRaw, useRoute } from "vue-router";
import { playgroundEasterEggClicked } from "@/shared/development/play-ground-easter-egg";
import { useTheme } from "@/shell/helpers/theme-definition";
import HeaderSearch from "@/shell/pages/header/search/header-search.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import QUANOS_LOGO from "@/shared/assets/images/quanos.svg?url";
import IconMenu from "@/shell/pages/header/components/toolbar-menu.vue";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { router } from "@/app/router.ts";

defineEmits<{
    (e: "toggleNav"): void;
}>();

function getSearchStringFromRoute(route: RouteLocationNormalizedLoaded) {
    return (parse(route.params?.search as string).text as string) ?? undefined;
}

const route = useRoute();
const { isXSmall } = useScreenSize();
const { themeDefinitions } = useTheme();
const { getPathToRoot } = useAbilityRoute();

const imageLoaded = ref(false);
const searchPhrase = ref<string>(getSearchStringFromRoute(route));

watch(
    () => route.params,
    () => {
        searchPhrase.value = getSearchStringFromRoute(route);
    },
    { immediate: true }
);

const themeLogoURL = computed(() => {
    const _themeLogoUrl = themeDefinitions.value?.logoURL;
    if (_themeLogoUrl === "" || _themeLogoUrl === undefined) {
        return undefined;
    } else {
        return _themeLogoUrl;
    }
});

const themeTitle = computed(() => {
    return themeDefinitions.value?.title;
});

const onSearch = (path: RouteLocationRaw) => {
    router.push(path);
};

function pathToRoot() {
    return getPathToRoot({});
}

const logoClickedEasterEgg = () => {
    playgroundEasterEggClicked();
};

const isMediumSmall = ref(false);
const onResize = (size: { width: number }) => {
    isMediumSmall.value = size.width < 800;
};

const themeTitleIsLong = computed(() => {
    return !!(themeTitle.value && themeTitle.value?.length > 12 && themeDefinitions.value?.showTitle);
});

const menuLogoImgRef = ref<HTMLImageElement | null>(null);

// If the image is an svg it needs a fixed width.
watch([themeDefinitions, menuLogoImgRef], () => {
    if (themeDefinitions.value && menuLogoImgRef.value) {
        if (themeDefinitions.value.logoPath === "" || /\.(svg)$/.test(themeDefinitions.value?.logoPath)) {
            menuLogoImgRef.value.style.width = "143px";
        } else {
            menuLogoImgRef.value.style.width = "auto";
        }
    }
});
</script>

<style lang="scss">
.header-component {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    flex: 0 0 auto;
    z-index: 100;

    .screen--xs & {
        box-shadow: none;
    }
}
</style>

<style lang="scss" scoped>
.header-component {
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    flex: 0 0 auto;
    z-index: 100;

    .screen--xs & {
        box-shadow: none;
    }

    .tenant-title {
        font-size: 1rem;
        min-width: fit-content;
        padding: #{$spacing-m + $spacing-s};
        color: $default-text-color;
        font-weight: $semi-bold;
        margin-left: #{$spacing-m + $spacing-s};
        width: max-content;
        border-left: 1px solid $default-border-color;

        &.under-image {
            margin-left: 0;
            margin-top: $spacing-s;
            padding: 0;
            background-color: none;
            font-size: 1.1rem;
            border-left: none;
            .screen--sm & {
                font-size: 1rem;
                margin-left: $spacing-s;
            }
        }

        .screen--sm & {
            font-size: 1.1rem;
            margin-left: $spacing-s;
        }
    }
    .menu-logo {
        .menu-logo-img {
            height: 55px;
            width: 200px;

            &.theme-title-long {
                width: auto;
                max-height: 32px;

                .screen--sm & {
                    max-height: 28px;
                }
            }

            .screen--sm & {
                max-height: 30px;
            }
        }
    }
}
</style>
