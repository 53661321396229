<template>
    <MainContainer>
        <template #header>
            <PageHeader v-if="!hideTitle" :title="$t('activities.Activities')" show-bread-crumbs-from-route />
        </template>
        <ListFrame
            class="activities-overview"
            :loading="initialActivitiesLoading"
            :no-results="!resultExists"
            :no-results-error-message="noResultsErrorMessage"
        >
            <template #filter="{ isMobile }">
                <ListFilterRendererUrlSync
                    v-model:selected="selected"
                    sort-save-key="activities_sort"
                    :facet-collection-id="FacetCollection.activitiesSelection"
                    :fetch-aggregation="fetchAggregation"
                    :is-mobile-view="isMobile"
                    :product="product"
                    :more-menu-entries="moreMenuEntries"
                    :sort-order-entries="sortOrderItems"
                    @on-open-filter="onOpenFilterHandler"
                    @on-sort-selection-changed="onChangeSortOrder"
                />
            </template>
            <template #count>
                {{ searchHitsText }}
            </template>
            <template #content>
                <!-- The loading spinner in the ListFrame is not compatible with the InfinityScroll
                therefore the workaround is necessary with refetchloading and own loading spinner in the template content -->
                <div v-if="refetchLoading">
                    <div class="loading-container small-content-container" data-qs="loading-fragment-spinner">
                        <q-spinner-ios size="2em" />
                    </div>
                </div>
                <ActivitiesList
                    :activities="activities ?? []"
                    :search-context="searchContext"
                    @load-more="onLoadMore"
                    @toggle-collection-overlay="toggleCollectionActivityOverlay"
                />
            </template>
        </ListFrame>
    </MainContainer>
    <ShowIfHasAccess :resource="AccessResource.collections" :action="AccessAction.write" :ignore-conditions="true">
        <CollectionActivityOverlay
            v-if="selectedActivity"
            :activity="selectedActivity"
            :asset-id="asset"
            :product-id="product"
            @on-close="closeCollectionOverlay"
            @on-save-succeeded="closeCollectionOverlay"
        />
    </ShowIfHasAccess>
</template>

<script setup lang="ts">
import ActivitiesList from "@/abilities/activities/components/activities-list.vue";
import ListFilterRendererUrlSync from "@/shared/list-filter/list-filter-renderer-url-sync.vue";
import { computed, ComputedRef, ref, toRefs, watch } from "vue";
import { Facet, FacetCollection, FacetSelected } from "@/shared/facets/facets.model";
import { useFilteredActivityResults } from "@/abilities/activities/composeables/filtered-activity-results.ts";
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import ListFrame from "@/shared/list-frame/list-frame.vue";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { useI18n } from "vue-i18n";
import MainContainer from "@/shell/pages/layout/main-container/main-container.vue";
import PageHeader from "@/shell/pages/layout/page-header/page-header.vue";
import { useActivitiesSortOrderItems } from "@/abilities/activities/composeables/activities-sort-order-items";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { generateSearchHitsText } from "@/shared/utils/total-formatter";
import CollectionActivityOverlay, {
    ActivityModelOverlay,
} from "@/abilities/collections/components/overlay/collection-activity-overlay.vue";
import { ActivityResult } from "@/abilities/activities/models/activity.model.ts";
import ShowIfHasAccess from "@/shared/access-control/components/show-if-has-access.vue";
import { useProductAssetTitle } from "@/shared/composables/product-asset-title.ts";
import { useBrowserTitle } from "@/shared/composables/use-browser-title.ts";
import { router } from "@/app/router.ts";
import { getUiLanguage } from "@/shared/services/providers/language-provider.ts";
import { watchImmediate } from "@vueuse/core";

const LAZY_LOAD_AMOUNT = 10;

const props = withDefaults(
    defineProps<{
        text?: string;
        product?: string;
        asset?: string;
        article?: string;
        hideTitle?: boolean;
        browserTitle?: boolean;
    }>(),
    { browserTitle: true }
);

const { text, product, article, asset } = toRefs(props);
const selected = ref<FacetSelected[]>([]);
const facet = ref<Facet>();
const sortEntry = ref<SortEntry | undefined>();
const selectedActivity = ref<ActivityModelOverlay | undefined>();
const noResultButFiltersAreSet = ref(false);
const isOnMoreLoading = ref(false);

const { t } = useI18n();
const { productTitle } = useProductAssetTitle(product);
const { setTitle } = useBrowserTitle();

const currentLanguage = computed(() => getUiLanguage());

const searchContext = computed(() => {
    return { product: product.value, article: article.value, asset: asset.value };
});

const searchPhrase = computed(() => {
    return text?.value ?? "";
});

const { hasAccess: hasConfigAccess } = useHasAccess({
    resource: AccessResource.activities,
    action: AccessAction.write,
});

const { hasAccess: hasFilterConfigAccess } = useHasAccess({
    resource: AccessResource.facet_collections,
    action: AccessAction.write,
});

const { activities, total, fetchAggregation, loadMore, loading } = useFilteredActivityResults({
    selected,
    facet,
    searchContext,
    searchPhrase,
    sort: sortEntry,
});

watchImmediate(
    () => [asset, productTitle, currentLanguage],
    () => {
        if (!props.browserTitle) return;

        let contextTitle = "";
        if (asset.value) contextTitle = ` (${asset.value})`;
        else if (productTitle.value) contextTitle = ` (${productTitle.value})`;

        setTitle(`${t("activities.Activities")}${contextTitle}`);
    },
    { deep: true }
);

const initialActivitiesLoading = computed(() => {
    return !activities.value || loading.value;
});

const refetchLoading = computed(() => {
    return loading.value && !isOnMoreLoading.value;
});

const onOpenFilterHandler = (facetProp: Facet) => {
    facet.value = facetProp;
};

const { sortOrderItems } = useActivitiesSortOrderItems();

const searchHitsText = computed(() => {
    return generateSearchHitsText(total.value);
});

const onLoadMore = async (done: (stop: boolean) => void) => {
    isOnMoreLoading.value = true;

    if (!activities.value || activities.value.length < 1 || activities.value.length === total.value?.count) {
        done(true);
        return;
    }

    const lastCursor = activities.value[activities.value.length - 1].cursor ?? "";

    try {
        const loadMoreLastCursor = await loadMore(lastCursor, LAZY_LOAD_AMOUNT);
        done(loadMoreLastCursor ? lastCursor === loadMoreLastCursor : true);
    } catch (e) {
        console.error(e);
    } finally {
        isOnMoreLoading.value = true;
    }
};

const resultExists = computed(() => {
    return total.value ? total.value.count > 0 : false;
});

watch(activities, () => {
    noResultButFiltersAreSet.value = !resultExists.value && selected.value.length > 0;
});

const noResultsErrorMessage = computed(() => {
    if (noResultButFiltersAreSet.value) {
        return t("activities.No activities found with the set filters Adjust the filter criteria to get more results");
    }
    if (props.text) {
        return t("core.No search results found");
    } else {
        return t("activities.No activities available");
    }
});

const moreMenuEntries: ComputedRef<MenuTextEntry[]> = computed(() => {
    return [
        {
            text: t("core.Configure view"),
            icon: "fa-regular fa-sliders-simple icon-color",
            click: () => {
                const route = router.resolve({ path: "/admin/datadisplay/activities-selection" });
                window.open(route.href, "_blank");
            },
            visible: hasConfigAccess.value,
            elementAttrs: { "data-qs": "more-menu-configure-activities-view" },
        },
        {
            text: t("core.Configure filter"),
            icon: "fa-regular fa-sliders-simple icon-color",
            click: () => {
                const route = router.resolve({ path: "/admin/filter/activities-selection" });
                window.open(route.href, "_blank");
            },
            visible: hasFilterConfigAccess.value,
            elementAttrs: { "data-qs": "more-menu-configure-activities-filter" },
        },
    ];
});

const toggleCollectionActivityOverlay = (activity: ActivityResult | undefined) => {
    if (activity === undefined || selectedActivity.value?.id === activity.id) {
        selectedActivity.value = undefined;
        return;
    }

    selectedActivity.value = { id: activity.id, title: activity.title, language: activity.language };
};

const onChangeSortOrder = (_sortEntry: SortEntry) => {
    sortEntry.value = _sortEntry;
};

const closeCollectionOverlay = () => {
    selectedActivity.value = undefined;
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 1.714rem;
    font-weight: $bold;
    padding-bottom: $spacing-l;
}
.loading-container {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 200px;
}
.refetch-loading {
    .q-scrollarea {
        opacity: 0.6;
    }
}
</style>
