import { MechanicSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { DocumentNode } from "@apollo/client/core";
import { computed, ComputedRef } from "vue";
import { Aggregations, Facet } from "@/shared/facets/facets.model";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { getAggregationsForFacet } from "@/shared/facets/composables/facets-aggregation";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { useI18n } from "vue-i18n";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import { router } from "@/app/router.ts";

export const getAggregations = async (facet: Facet, query: DocumentNode, variables: any): Promise<Aggregations> => {
    const aggregationsForDatafield = await consumerClient.query({
        query,
        variables,
        fetchPolicy: "no-cache",
    });

    return getAggregationsForFacet(facet, aggregationsForDatafield?.data?.mechanics?.aggregations);
};

export function useMechanicArticlesListSort() {
    const { t } = useI18n();

    const sortOrderItems = computed(() => {
        return [
            {
                id: "Ranking",
                title: t("core.Ranking"),
                value: {
                    field: MechanicSortFields.score,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Articlenumber ascending",
                title: t("mechanic.Article number ascending"),
                value: {
                    field: MechanicSortFields.articleNumber,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Articlenumber descending",
                title: t("mechanic.Article number descending"),
                value: {
                    field: MechanicSortFields.articleNumber,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Title ascending",
                title: t("mechanic.Title ascending"),
                value: {
                    field: MechanicSortFields.title,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Title descending",
                title: t("core.Title descending"),
                value: {
                    field: MechanicSortFields.title,
                    order: SortOrder.desc,
                },
            },
        ];
    });

    return { sortOrderItems };
}

export function useMechanicArticleListConfig() {
    const { t } = useI18n();

    const { hasAccess: hasConfigAccess } = useHasAccess({
        resource: AccessResource.data_display_configs,
        action: AccessAction.write,
    });

    const { hasAccess: hasFilterConfigAccess } = useHasAccess({
        resource: AccessResource.facet_collections,
        action: AccessAction.write,
    });

    const configMenuEntries: ComputedRef<MenuTextEntry[]> = computed(() => {
        return [
            {
                text: t("core.Configure view"),
                icon: "fa-regular fa-sliders-simple icon-color",
                click: () => {
                    const route = router.resolve({ path: "/admin/datadisplay/parts" });
                    window.open(route.href, "_blank");
                },
                visible: hasConfigAccess.value,
                elementAttrs: { "data-qs": "mechanic-article-settings-toolbar-configure-button" },
            },
            {
                text: t("core.Configure filter"),
                icon: "fa-regular fa-sliders-simple icon-color",
                click: () => {
                    const route = router.resolve({ path: "/admin/filter/parts" });
                    window.open(route.href, "_blank");
                },
                visible: hasFilterConfigAccess.value,
                elementAttrs: { "data-qs": "mechanic-article-settings-toolbar-configure-filter-button" },
            },
        ];
    });

    return { configMenuEntries };
}
