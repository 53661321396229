<template>
    <ConfirmDialogue ref="confirmDialogue" type="danger"
        ><p>
            {{ deleteMessage }}
        </p></ConfirmDialogue
    >
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue";
import ConfirmDialogue from "@/shared/components/popup/confirm-dialogue.vue";
import { DialogueOptions } from "@/shared/components/popup/confirm-dialogue.model";
import { useDeleteProductsMutation } from "@/shared/services/graphql/generated/admin-graph-types";
import { CommonRouteAliases, CommonTabAliases } from "@/shared/environment/common-route-aliases";
import { CURRENT_TAB_PARAM_NAME, TAB_PRODUCTS_ALIAS } from "../helpers/product-selection-routing";
import { functionText, keyText } from "@/shared/i18n/translation-types";
import { useProductsAssetsHistory } from "../store/products-assets-history";
import { useQuasar } from "quasar";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";
import { router } from "@/app/router.ts";

const props = defineProps<{
    productId: string;
    notifyAfterAction: (wasCanceled: boolean) => void;
}>();

const { notify } = useQuasar();
const { getPathToChildWithoutParents } = useAbilityRoute();

const {
    mutate: deleteProduct,
    onDone: onProductDeleted,
    onError: onProductDeletedError,
} = useDeleteProductsMutation({
    clientId: "admin",
});

const productNameLower = useProductNames("singularLower");
const productIsBeingDeletedText = useProductNames("productIsBeingDeleted");
const productIsDeletedText = useProductNames("productIsDeleted");

const deleteMessage = useAssetNames(
    "ifDeleteAllAssetsOptionsMechanicWillDeleted",
    computed(() => {
        return {
            product: props.productId,
        };
    })
);

onProductDeleted(() => {
    notify({
        message: productIsDeletedText.value,
        type: "success",
        timeout: 1_000,
    });
    router.push(
        getPathToChildWithoutParents(
            CommonRouteAliases.productSelection,
            { [CommonRouteAliases.productSelection]: CommonTabAliases.search },
            {
                [CURRENT_TAB_PARAM_NAME]: TAB_PRODUCTS_ALIAS,
            }
        )
    );
});

onProductDeletedError((error) => {
    notify(error.message);
});

const confirmDialogue = ref<{ show: (opts: DialogueOptions) => any } | null>(null);

const { deleteProductIdFromHistory } = useProductsAssetsHistory();

async function openDeletionDialogue() {
    await nextTick();
    const ok = await confirmDialogue.value?.show({
        title: functionText((t) => {
            return t("product-and-asset.Do you really want to delete {item}", { item: props.productId });
        }),
        okButtonText: functionText((t) => {
            return t("product-and-asset.Delete {productsName}", { productsName: productNameLower.value });
        }),
        cancelButtonText: keyText("core.Cancel"),
        icon: "fa-regular fa-triangle-exclamation error-color fa-lg",
    });
    // If you throw an error, the method will terminate here unless you surround it with try/catch
    if (ok) {
        deleteProduct({
            ids: [props.productId],
        });
        await deleteProductIdFromHistory(props.productId);

        notify({
            message: productIsBeingDeletedText.value,
            timeout: 1_000,
        });
        props.notifyAfterAction(false);
    } else {
        props.notifyAfterAction(true);
    }
}

onMounted(() => {
    openDeletionDialogue();
});
</script>

<style lang="scss" scoped></style>
